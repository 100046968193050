import React from 'react';
import PropTypes from 'prop-types';
import styles from './Catalog.module.css';
import { ForceSelectProfileContainer } from './ForceSelectProfile';
import { connect } from 'react-redux';
import FeaturedMovie from './FeaturedMovie';
import MovieCategory from './MovieCategory';
import { getCatalogWithMyListDetails } from './store/selectors';
import { toggleOnMyList } from './store/actions';

export const Catalog = ({ catalog, onToggleMyList }) => (
  <section className={styles.catalog}>
    <ForceSelectProfileContainer />
    <FeaturedMovie
      movie={catalog.filter(c => c.category === 'Featured')[0].movies[0]}
      onToggleMyList={onToggleMyList}
    />
    {/* Typically using an index as a key is an antipattern, but it's fine here
        because the categories are not changing. */}
    {catalog.map(
      (category, index) =>
        category.category !== 'Featured' && (
          <MovieCategory
            key={index}
            {...category}
            onToggleMyList={onToggleMyList}
          />
        )
    )}
  </section>
);

Catalog.propTypes = {
  catalog: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      movies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          thumbnail: PropTypes.string.isRequired,
          isInMyList: PropTypes.bool.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onToggleMyList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  catalog: getCatalogWithMyListDetails(state),
});
const mapDispatchToProps = dispatch => ({
  onToggleMyList: movieId => dispatch(toggleOnMyList({ movieId })),
});

export const CatalogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalog);
