import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { NavContainer } from './Nav';
import { ProfileSelectorContainer } from './ProfileSelector';
import { CatalogContainer } from './Catalog';
import { MyListContainer } from './MyList';

const App = () => (
  <BrowserRouter>
    <NavContainer />

    <Route path="/" exact component={CatalogContainer} />
    <Route path="/my-list" exact component={MyListContainer} />
    <Route path="/select-profile" exact component={ProfileSelectorContainer} />
  </BrowserRouter>
);

export default App;
