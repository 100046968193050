import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DropZone.module.css';
import cx from 'classnames';

const DropZone = ({ onDrop, isLast }) => {
  const [isHovering, setHovering] = useState(false);

  return (
    <div
      onDrop={e => {
        e.preventDefault();
        onDrop();
      }}
      onDragEnter={e => {
        setHovering(true);
        e.preventDefault();
      }}
      onDragOver={e => {
        setHovering(true);
        e.preventDefault();
      }}
      onDragLeave={e => {
        setHovering(false);
        e.preventDefault();
      }}
      className={cx(styles.zone, {
        [styles.last]: isLast,
        [styles.hover]: isHovering,
      })}
    />
  );
};

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
};

DropZone.defaultProps = {
  isLast: false,
};

export default DropZone;
