import { combineReducers } from 'redux';
import { SELECT_PROFILE, TOGGLE_ON_MY_LIST, REORDER_LIST } from './actions';

function profiles(state = []) {
  return state;
}

function currentProfileId(state = null, { type, payload }) {
  switch (type) {
    case SELECT_PROFILE:
      return payload.profileId;
    default:
      return state;
  }
}

function catalog(state = []) {
  return state;
}

function lists(state = {}, { type, payload }) {
  switch (type) {
    case TOGGLE_ON_MY_LIST:
      const listToToggle = state[payload.profileId] || [];
      const index = listToToggle.indexOf(payload.movieId);
      const newList =
        index > -1
          ? [...listToToggle.slice(0, index), ...listToToggle.slice(index + 1)]
          : [payload.movieId, ...listToToggle];
      // Replace the original state object's key associated with the curent
      // profile.
      return {
        ...state,
        [payload.profileId]: newList,
      };

    case REORDER_LIST:
      const listToReorder = state[payload.profileId].slice();
      const currentIndex = listToReorder.indexOf(payload.movieId);
      listToReorder.splice(currentIndex, 1);
      listToReorder.splice(payload.targetIndex, 0, payload.movieId);

      // Replace the original state object's key associated with the curent
      // profile.
      return {
        ...state,
        [payload.profileId]: listToReorder,
      };

    default:
      return state;
  }
}

export default combineReducers({
  profiles,
  currentProfileId,
  catalog,
  lists,
});
