import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Used to redirect you to the select-profile page if you have not selected one.
export const ForceSelectProfile = ({ isSelected }) =>
  isSelected ? null : <Redirect to="/select-profile" />;

ForceSelectProfile.propTypes = {
  isSelected: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSelected: !!state.currentProfileId,
});
export const ForceSelectProfileContainer = connect(mapStateToProps)(
  ForceSelectProfile
);
