import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PreviewVideo.module.css';
import cx from 'classnames';

const PreviewVideo = ({ src, className }) => {
  const [hasEntered, setHasEntered] = useState(false);

  return (
    <video
      className={cx(styles.video, className, { [styles.enter]: hasEntered })}
      autoPlay
      playsInline
      muted
      loop
      src={src}
      onPlaying={() => setHasEntered(true)}
    />
  );
};
PreviewVideo.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PreviewVideo.defaultProps = {
  className: null,
};

export default PreviewVideo;
