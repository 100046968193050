import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './MovieCategory.module.css';
import Movie from './Movie';
import DropZone from './DropZone';
import cx from 'classnames';

const MovieCategory = ({
  category,
  movies,
  onToggleMyList,
  canReorder,
  onReorder,
  stackedOnMobile,
}) => {
  const [draggingInfo, setDraggingInfo] = useState({
    active: false,
    movieId: null,
  });
  const [dropCount, setDropCount] = useState(0);
  const _onReorder = opts => {
    setDropCount(dropCount + 1);
    onReorder(opts);
  };

  return (
    <div
      className={cx(styles.category, {
        [styles.stackedOnMobile]: stackedOnMobile,
      })}
    >
      <h3 className={styles.title}>{category}</h3>
      {movies.length > 0 && (
        <div className={styles.movies}>
          <div className={styles.moviesInner}>
            {movies.map((movie, index) => {
              const shouldRenderLeftDropZone =
                draggingInfo.active &&
                draggingInfo.movieId !== movie.id &&
                draggingInfo.movieId !== (movies[index - 1] || {}).id;
              const shouldRenderRightDropZone =
                draggingInfo.active &&
                index === movies.length - 1 &&
                draggingInfo.movieId !== movie.id;

              // Do this crazy business with counting drops so that we can
              // forcibly re-render elements after a drag and drop is finished.
              // Even though we are not playing games with the DOM and relying on
              // React to render in response to data changing, it is possible in
              // some browsers to have a mouse event fire where the cursor _used
              // to be_ after a drop event fires, not where it current is.
              // This results in undesirable hover effects.
              const key = `${dropCount}-${movie.id}`;
              return (
                <div className={styles.movieWrapper} key={key}>
                  {shouldRenderLeftDropZone && (
                    <DropZone
                      onDrop={() =>
                        _onReorder({
                          movieId: draggingInfo.movieId,
                          targetIndex: index,
                        })
                      }
                    />
                  )}
                  <Movie
                    {...movie}
                    onToggleMyList={onToggleMyList}
                    canReorder={canReorder}
                    onDragStart={movieId =>
                      setDraggingInfo({ active: true, movieId })
                    }
                    onDragEnd={() => setDraggingInfo({ active: false })}
                    onMoveToBeginning={
                      index > 0
                        ? () =>
                            _onReorder({ movieId: movie.id, targetIndex: 0 })
                        : null
                    }
                  />
                  {shouldRenderRightDropZone && (
                    <DropZone
                      isLast
                      onDrop={() =>
                        _onReorder({
                          movieId: draggingInfo.movieId,
                          targetIndex: index + 1,
                        })
                      }
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

MovieCategory.propTypes = {
  category: PropTypes.string.isRequired,
  movies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
    })
  ).isRequired,
  onToggleMyList: PropTypes.func.isRequired,
  canReorder: PropTypes.bool,
  stackedOnMobile: PropTypes.bool,
};

MovieCategory.defaultProps = {
  canReorder: false,
  stackedOnMobile: false,
};

export default MovieCategory;
