// API for fetching and persisting data. Uses localStorage instead of an actual
// backend.

export const getProfiles = () => [
  { id: 1, name: 'Jill', avatar: '🦁' },
  { id: 2, name: 'Jerry', avatar: '👻' },
  { id: 3, name: 'Jess', avatar: '🤦‍♀️' },
];

export const getCurrentProfileId = () => {
  const profileId = localStorage.getItem('currentProfileId');
  if (profileId) return +profileId;
  return null;
};

export const setCurrentProfileId = profileId =>
  localStorage.setItem('currentProfileId', profileId);

export const getLists = () => {
  try {
    return JSON.parse(localStorage.getItem('lists')) || {};
  } catch (e) {
    return {};
  }
};

export const updateLists = lists =>
  localStorage.setItem('lists', JSON.stringify(lists));

export const getCatalog = () => {
  return [
    {
      category: 'Featured',
      movies: [
        {
          id: 1,
          title: 'What a Wonderful Web We Weave',
          description:
            'A young woman discovers the delights of modern JavaScript tooling. Will her honeymoon phase see her through to the completion of her Webpack configuration?',
          thumbnail: '/images/1.jpeg',
          hero: '/images/1-large.jpeg',
          preview: '/videos/Date.mp4',
        },
      ],
    },
    {
      category: 'Trending Now',
      movies: [
        {
          id: 11,
          title: 'I Know What You Did Last Easter',
          thumbnail: '/images/7.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 12,
          title: 'True Crime',
          thumbnail: '/images/1.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 13,
          title: 'The Wild Banana',
          thumbnail: '/images/5.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 14,
          title: 'The Magic of Our Time',
          thumbnail: '/images/3.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 15,
          title: 'Man Later, Boy First',
          thumbnail: '/images/2.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 16,
          title: 'Passing Pleasure',
          thumbnail: '/images/6.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 17,
          title: 'The Scent of Summer',
          thumbnail: '/images/7.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 18,
          title: 'Dreams in Dreams of Dreams',
          thumbnail: '/images/4.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 19,
          title: 'Game of Thrones Knockoff',
          thumbnail: '/images/2.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
      ],
    },
    {
      category: 'Emotional Teen Dramas',
      movies: [
        {
          id: 21,
          title: 'Girl, What?',
          thumbnail: '/images/4.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 22,
          title: 'Band of Marching Band Brothers',
          thumbnail: '/images/3.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 23,
          title: 'Parents, Right?',
          thumbnail: '/images/2.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 24,
          title: 'High School is Actually Kind of OK',
          thumbnail: '/images/6.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 25,
          title: 'Science Fair Fright',
          thumbnail: '/images/7.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 26,
          title: 'Forever Fifteen',
          thumbnail: '/images/3.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 27,
          title: "Boys Just Don't Get It",
          thumbnail: '/images/1.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 28,
          title: 'College, Maybe a Little Too Much Freedom',
          thumbnail: '/images/5.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 29,
          title: 'Secrets, Secrets',
          thumbnail: '/images/4.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
      ],
    },
    {
      category: 'Nostalgia-Inducing Feel-Goods',
      movies: [
        {
          id: 31,
          title: "That Band From the 90's",
          thumbnail: '/images/6.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 32,
          title: 'Generic Disney Movie',
          thumbnail: '/images/5.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 33,
          title: 'Sesame Street, the Movie',
          thumbnail: '/images/4.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 34,
          title: 'The Empire Strikes Back One More Time',
          thumbnail: '/images/3.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 35,
          title: '2000: A Launch Pad Odyssey',
          thumbnail: '/images/2.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 36,
          title: 'Slightly Misogynistic Spy Movie',
          thumbnail: '/images/1.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 37,
          title: 'That Movie With All The Swear Words',
          thumbnail: '/images/6.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 38,
          title: 'Unrealistic Zombie Thriller',
          thumbnail: '/images/7.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 39,
          title: 'Sports Team Wins In The End!',
          thumbnail: '/images/4.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
      ],
    },
    {
      category: "You'll Stop Eating Chicken After These Documentaries",
      movies: [
        {
          id: 41,
          title: 'Chickens Have Feelings',
          thumbnail: '/images/1.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 42,
          title: 'Eggs Are Baby Chick Spirits, You Know',
          thumbnail: '/images/3.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 43,
          title: 'Cooped Up, Let Down',
          thumbnail: '/images/2.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 44,
          title: "You'll Never Name Your Kid Tyson After Seeing This",
          thumbnail: '/images/4.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 45,
          title: 'Do You Really Need That McNugget, Karen?',
          thumbnail: '/images/5.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 46,
          title: 'Balk at the Barbecue',
          thumbnail: '/images/7.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
        {
          id: 47,
          title: "Have I Told You I'm Going Vegan?",
          thumbnail: '/images/6.jpeg',
          preview: '/videos/Beachkids.mp4',
        },
        {
          id: 48,
          title: 'Gobble These Veggies Please',
          thumbnail: '/images/1.jpeg',
          preview: '/videos/SunsetCouple.mp4',
        },
        {
          id: 49,
          title: "Free Range Isn't Free",
          thumbnail: '/images/2.jpeg',
          preview: '/videos/DancingFlower.mp4',
        },
      ],
    },
  ];
};
