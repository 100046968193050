import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './FeaturedMovie.module.css';
import PlusSimpleIcon from './icons/PlusSimpleIcon';
import DoneSimpleIcon from './icons/DoneSimpleIcon';
import PreviewVideo from './PreviewVideo';
import cx from 'classnames';

export const FeaturedMovie = ({ movie, onToggleMyList }) => {
  // We want to show the preview video when hovering.
  // In order to provide a smooth experience, don't rip the video out as soon
  // as hovering stops.
  // Instead, apply a class to transition the video down to 0 opacity over 1.5s.
  // After that time, rip it out of the dom.
  const [isHovering, setIsHovering] = useState(false);
  const [isGracefullyLeaving, setIsGracefullyLeaving] = useState(false);
  const [gracefulTimeout, setGracefulTimeout] = useState(false);
  // Ensure we clean up the timeout so we don't try to set state after unmount.
  useEffect(() => () => {
    clearTimeout(gracefulTimeout);
  });

  const handleMouseEnterOrOver = () => {
    setIsHovering(true);
    setIsGracefullyLeaving(false);
    clearTimeout(gracefulTimeout);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
    setIsGracefullyLeaving(true);
    const gracefulTimeout = setTimeout(() => {
      setIsGracefullyLeaving(false);
    }, 1500);
    setGracefulTimeout(gracefulTimeout);
  };

  return (
    <div
      className={styles.hero}
      style={{ backgroundImage: `url(${movie.hero})` }}
      onMouseEnter={handleMouseEnterOrOver}
      onMouseOver={handleMouseEnterOrOver}
      onMouseLeave={handleMouseLeave}
    >
      {(isHovering || isGracefullyLeaving) && (
        <PreviewVideo
          className={cx(styles.video, {
            [styles.gracefullyLeaving]: isGracefullyLeaving,
          })}
          src={movie.preview}
        />
      )}
      <div className={styles.container}>
        <h1 className={styles.title}>{movie.title}</h1>
        <button
          className={styles.button}
          onClick={() => onToggleMyList(movie.id)}
        >
          {movie.isInMyList ? <DoneSimpleIcon /> : <PlusSimpleIcon />}
          My List
        </button>
        <p className={styles.description}>{movie.description}</p>
      </div>
    </div>
  );
};

FeaturedMovie.propTypes = {
  movie: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isInMyList: PropTypes.bool.isRequired,
    hero: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
  }),
  onToggleMyList: PropTypes.func.isRequired,
};

export default FeaturedMovie;
