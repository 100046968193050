import React from 'react';

const PlusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 512 512">
    <circle r="200" cx="256" cy="256" fill="#e5e5e5" />
    <path
      fill="#141414"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
    />
  </svg>
);

export default PlusIcon;
