import React from 'react';
import styles from './Logo.module.css';

const Logo = () => (
  <span className={styles.logo}>
    <span className={styles.n}>N</span>
    <span className={styles.i}>I</span>
    <span className={styles.t}>T</span>
    <span className={styles.f}>F</span>
    <span className={styles.l}>L</span>
    <span className={styles.e}>E</span>
    <span className={styles.x}>X</span>
  </span>
);

export default Logo;
