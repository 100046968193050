import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Movie.module.css';
import PlusIcon from './icons/PlusIcon';
import DoneIcon from './icons/DoneIcon';
import BeginningIcon from './icons/BeginningIcon';
import cx from 'classnames';
import PreviewVideo from './PreviewVideo';

const Movie = ({
  id,
  title,
  thumbnail,
  preview,
  isInMyList,
  onToggleMyList,
  canReorder,
  onDragStart,
  onDragEnd,
  onMoveToBeginning,
}) => {
  // Manually track hover for styles because of a browser bug that hovers the
  // wrong element after rerender.
  const [isHovering, setIsHovering] = useState(false);
  const Icon = isInMyList ? DoneIcon : PlusIcon;
  const buttonLabel = `${isInMyList ? 'Remove from' : 'Add to'} my list`;
  return (
    <div
      className={cx(styles.movie, {
        [styles.hover]: isHovering,
        [styles.moveIt]: canReorder,
      })}
      draggable={canReorder}
      onDragStart={e => {
        // We don't care about any drag data, but this is needed per the spec.
        e.dataTransfer.setData('text', '');
        setIsHovering(false);
        onDragStart(id);
      }}
      onDragEnd={() => onDragEnd(id)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering && <PreviewVideo src={preview} />}

      <h6 className={styles.title}>{title}</h6>
      <button
        className={styles.button}
        aria-label={buttonLabel}
        title={buttonLabel}
        onClick={() => onToggleMyList(id)}
      >
        <Icon />
      </button>
      {canReorder && onMoveToBeginning && (
        <button
          className={styles.beginningButton}
          aria-label="Move to top of list"
          title="Move to top of list"
          onClick={onMoveToBeginning}
        >
          <BeginningIcon />
        </button>
      )}
      <div
        className={styles.thumbnail}
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
      />
    </div>
  );
};

Movie.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  isInMyList: PropTypes.bool.isRequired,
  onToggleMyList: PropTypes.func.isRequired,
  canReorder: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onMoveToBeginning: PropTypes.func,
};

Movie.defaultProps = {
  canReorder: false,
  onDragStart: () => {},
  onDragEnd: () => {},
  onMoveToBeginning: null,
};

export default Movie;
