import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './ProfileSelector.module.css';
import { selectProfile } from './store/actions';
import { Redirect } from 'react-router-dom';

export const ProfileSelector = ({ profiles, onSelectProfile }) => {
  const [hasChosen, markHasChosen] = useState(false);
  return (
    <section className={styles.selector}>
      <h1 className={styles.header}>Who's watching?</h1>
      <div className={styles.profiles}>
        {profiles.map(profile => (
          <button
            onClick={() => {
              onSelectProfile(profile);
              markHasChosen(true);
            }}
            className={styles.button}
            key={profile.id}
          >
            <span className={styles.avatar}>{profile.avatar}</span>
            {profile.name}
          </button>
        ))}
      </div>
      {hasChosen && <Redirect to="/" push />}
    </section>
  );
};

ProfileSelector.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelectProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profiles: state.profiles,
});
const mapDispatchToProps = dispatch => ({
  onSelectProfile: profile =>
    dispatch(selectProfile({ profileId: profile.id })),
});

export const ProfileSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSelector);
