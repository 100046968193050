import React from 'react';
import styles from './Nav.module.css';
import PropTypes from 'prop-types';
import Logo from './Logo';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrentProfile } from './store/selectors';

export const Nav = ({ location, profile }) => (
  <nav className={styles.nav}>
    <div className={styles.mainLinks}>
      <Link to="/">
        <Logo />
      </Link>

      {location.pathname !== '/select-profile' && (
        <>
          <NavLink
            to="/"
            exact
            activeClassName={styles.active}
            className={styles.navLink}
          >
            Movies
          </NavLink>
          <NavLink
            to="/my-list"
            exact
            activeClassName={styles.active}
            className={styles.navLink}
          >
            My List
          </NavLink>
        </>
      )}
    </div>
    {location.pathname !== '/select-profile' && profile && (
      <Link to="/select-profile" aria-label="Who's watching?">
        <span className={styles.avatar}>{profile.avatar}</span>
      </Link>
    )}
  </nav>
);

Nav.propTypes = {
  location: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }),
};

Nav.defaultProps = {
  profile: null,
};

export const NavWithRouter = withRouter(Nav);

const mapStateToProps = state => ({
  profile: getCurrentProfile(state),
});
export const NavContainer = connect(mapStateToProps)(NavWithRouter);
