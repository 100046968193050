import React from 'react';
import PropTypes from 'prop-types';
import styles from './MyList.module.css';
import { ForceSelectProfileContainer } from './ForceSelectProfile';
import { connect } from 'react-redux';
import MovieCategory from './MovieCategory';
import { getMoviesInMyList } from './store/selectors';
import { toggleOnMyList, reorderList } from './store/actions';

export const MyList = ({ movies, onToggleMyList, onReorder }) => (
  <section className={styles.myList}>
    <ForceSelectProfileContainer />
    <MovieCategory
      category="My List"
      movies={movies}
      onToggleMyList={onToggleMyList}
      canReorder
      onReorder={onReorder}
      stackedOnMobile
    />

    {movies.length === 0 && (
      <p className={styles.noTitlesYet}>
        You haven't added any titles to your list yet.
      </p>
    )}
  </section>
);

MyList.propTypes = {
  movies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      isInMyList: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onToggleMyList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  movies: getMoviesInMyList(state),
});
const mapDispatchToProps = dispatch => ({
  onToggleMyList: movieId => dispatch(toggleOnMyList({ movieId })),
  onReorder: ({ movieId, targetIndex }) =>
    dispatch(reorderList({ movieId, targetIndex })),
});

export const MyListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyList);
