import { createSelector } from 'reselect';

export const getCurrentProfileId = state => state.currentProfileId;
export const getProfiles = state => state.profiles;
export const getCurrentProfile = createSelector(
  [getCurrentProfileId, getProfiles],
  (profileId, profiles) => profiles.filter(p => p.id === profileId)[0]
);

export const getCatalog = state => state.catalog;
export const getLists = state => state.lists;
export const getMyList = createSelector(
  [getLists, getCurrentProfileId],
  (lists, currentProfileId) => lists[currentProfileId] || []
);

// Zip together the catalog and my list movie ids. We want to place an
// `isInMyList` boolean on each movie.
export const getCatalogWithMyListDetails = createSelector(
  [getCatalog, getMyList],
  (catalog, myList) =>
    catalog.map(category => ({
      ...category,
      movies: category.movies.map(movie => ({
        ...movie,
        isInMyList: myList.indexOf(movie.id) > -1,
      })),
    }))
);

// Filter the catalog down to a flat list of movies in my list.
// Order by the contents of my list.
export const getMoviesInMyList = createSelector(
  [getCatalogWithMyListDetails, getMyList],
  (catalog, myList) => {
    const moviesById = catalog.reduce((moviesById, category) => {
      category.movies.forEach(movie => (moviesById[movie.id] = movie));
      return moviesById;
    }, {});
    return myList.map(movieId => moviesById[movieId]);
  }
);
