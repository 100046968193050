import * as api from '../api';

export const SELECT_PROFILE = 'select profile';
export const selectProfile = ({ profileId }) => dispatch => {
  api.setCurrentProfileId(profileId);

  dispatch({
    type: SELECT_PROFILE,
    payload: {
      profileId,
    },
  });
};

export const TOGGLE_ON_MY_LIST = 'toggle on my list';
export const toggleOnMyList = ({ movieId }) => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_ON_MY_LIST,
    payload: {
      movieId,
      profileId: getState().currentProfileId,
    },
  });
  api.updateLists(getState().lists);
};

export const REORDER_LIST = 'reorder list';
export const reorderList = ({ movieId, targetIndex }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: REORDER_LIST,
    payload: {
      movieId,
      targetIndex,
      profileId: getState().currentProfileId,
    },
  });
  api.updateLists(getState().lists);
};
